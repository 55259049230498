/**
 * @generated SignedSource<<516fc1111d275c1ce421bd0e340be470>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserProfile_user$data = {
  readonly fullName: string | null;
  readonly emailAddress: string | null;
  readonly " $fragmentType": "UserProfile_user";
};
export type UserProfile_user$key = {
  readonly " $data"?: UserProfile_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfile_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserProfile_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    }
  ],
  "type": "CifUser",
  "abstractKey": null
};

(node as any).hash = "901d78b92744f20c9ff19672223e696b";

export default node;
