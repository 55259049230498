/**
 * @generated SignedSource<<01859f7fdbf28c84aa04b8f3def2a074>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type pagesQuery$variables = {};
export type pagesQuery$data = {
  readonly query: {
    readonly session: {
      readonly " $fragmentSpreads": FragmentRefs<"DefaultLayout_session">;
    } | null;
  };
};
export type pagesQuery = {
  variables: pagesQuery$variables;
  response: pagesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "pagesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "KeycloakJwt",
            "kind": "LinkedField",
            "name": "session",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DefaultLayout_session"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "pagesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "KeycloakJwt",
            "kind": "LinkedField",
            "name": "session",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CifUser",
                "kind": "LinkedField",
                "name": "cifUserBySub",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailAddress",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1cedc4c3649b221b009413490e408679",
    "id": null,
    "metadata": {},
    "name": "pagesQuery",
    "operationKind": "query",
    "text": "query pagesQuery {\n  query {\n    session {\n      ...DefaultLayout_session\n    }\n    id\n  }\n}\n\nfragment DefaultLayout_session on KeycloakJwt {\n  cifUserBySub {\n    ...UserProfile_user\n    id\n  }\n}\n\nfragment UserProfile_user on CifUser {\n  fullName\n  emailAddress\n}\n"
  }
};
})();

(node as any).hash = "a557d44ebae125a5e1d0bad1868b656b";

export default node;
